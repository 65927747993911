.bottomSection {
    @apply text-[#f6f6f6] bg-black;
}
.bottomWrap {
    @apply grid pt-16 gap-10;
    @apply border-b border-gray-600;
    @apply xl:grid-cols-2 xl:gap-28;
}
.Footer {
    @apply py-6;
    @apply flex items-baseline justify-between;
}
.SNSLink {
    @apply flex items-center gap-4 text-2xl;
}
.formInput {
    @apply focus:outline-none border-0 p-2 w-full text-black;
}
.formSubmit {
    @apply inline-block;
    @apply bg-gray-100 hover:bg-white;
    @apply text-gray-900 hover:text-black text-sm;
    @apply py-2 px-6 rounded-md;
    @apply transition-colors;
}
