.formInput {
    @apply focus:outline-none border-0 p-2 w-full text-black;
}
.formSubmit {
    @apply inline-block;
    @apply bg-white;
    @apply text-black hover:text-black font-medium;
    @apply py-2 px-6 rounded-md;
    @apply transition-colors;
}
