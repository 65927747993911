.navbar header {
    @apply max-w-7xl px-8;
}
.logo {
    @apply flex flex-shrink-0 items-center;
}
.logoImage {
    @apply max-h-[45px] w-auto;
}
.navbarOpen[data-menu-open='true'] {
    @apply border-b border-solid;
}
.cat_button {
    @apply bg-white text-black uppercase font-bold border-black text-sm h-unit-8 px-unit-3 !important;
    @apply transition duration-300;
    @apply hover:bg-black hover:text-white !important;
}
.mobilenav {
    @apply px-8 pt-6 list-none;
}
